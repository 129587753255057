import React, { Component } from 'react';

class Impressum extends Component {
  render() {
    return (
      <div className="p-20 container">
        <h1 className="text-2xl font-bold mb-4">TendoTec</h1>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Inhaber:</h2>
          <p>Abdulrahim Aldaeef</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Address:</h2>
          <p>Favoritenstrasse 61/1+18</p>
          <p>1100 Wien</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Contact:</h2>
          <p>Telefon: <a href="tel:+436766999888" className="text-blue-500 underline">+4367 66 999 888</a></p>
          <p>Email: <a href="mailto:office@tendotec.at" className="text-blue-500 underline">office@tendotec.at</a></p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">UID-Nr.:</h2>
          <p>ATU76982357</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">EORI-Nr.:</h2>
          <p>ATEOS1000098754</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">GISA-Zahl:</h2>
          <p>33837192</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Authority according to ECG (E-Commerce Law):</h2>
          <p>Magistratisches Bezirksamt des X. Bezirkes</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">LI Mechatroniker:</h2>
          <p>
          Gewerbewortlaut Mechatroniker für Elektronik, Büro- und EDV-Systemtechnik, eingeschränkt auf Service und Reparatur von Smartphones, Tablets, PC´s und Laptops mittels vorgefertigter Komponenten
          </p>
        </div>
      </div>
    );
  }
}

export default Impressum;
