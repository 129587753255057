import * as React from 'react';

 interface  nameSrcImage {
  [name:string]:  string
}
export interface ICardImageProps {
    name: string
    isWithimg?:boolean
    onClick: () => void;
    
}

export interface ICardImageState {
  allImge:nameSrcImage
}

export default class CardImage extends React.Component<ICardImageProps, ICardImageState> {
  constructor(props: ICardImageProps) {
    super(props);

    this.state = {
      allImge:
      {
        "iPhone": "../../../img/service/iphone/type/iphone-cat.png",
        "IPAD": "../../../img/service/iphone/ipad/IPAD.png",
        "Mac & mehr": "../../../img/service/iphone/type/macbook-cat.png",
        "MusicElectronics": "../../../img/service/iphone/type/MusicElectronics-cat.png",
        "Laptop": "../../../img/service/iphone/laptop/laptop.png",
        "Acer": "../../../img/service/iphone/laptop/acer.png",
        "asus": "../../../img/service/iphone/laptop/asus.png",
        "Dell": "../../../img/service/iphone/laptop/dell.png",
        "HP": "../../../img/service/iphone/laptop/hp.png",
        "Lenovo": "../../../img/service/iphone/laptop/lenovo.png",
        "MSI": "../../../img/service/iphone/laptop/msi.png",
        "Asus": "../../../img/service/iphone/laptop/asus.png",
        "Fujitsu": "../../../img/service/iphone/laptop/fujitsu.png",
        "Toshiba": "../../../img/service/iphone/laptop/toshiba.png",

        "SmartPhone": "../../../img/service/iphone/smartphone/smartphone2.webp",
        "Huawei": "../../../img/service/iphone/smartphone/huawei.png",
        "Samsung": "../../../img/service/iphone/smartphone/samsung.png",
        "LG": "../../../img/service/iphone/smartphone/LG.png",
        "OnePlus": "../../../img/service/iphone/smartphone/oneplus.webp",
        "Nokia": "../../../img/service/iphone/smartphone/NOKIA.png",
        "Sony": "../../../img/service/iphone/smartphone/Sony.png",

        "iPhone 6": "../../../img/service/iphone/phones/iph6.png",
        "iPhone 7": "../../../img/service/iphone/phones/iph7.png",
        "iPhone 8": "../../../img/service/iphone/phones/iph8.png",
        "iPhone SE": "../../../img/service/iphone/phones/iph9.png",
        "iPhone X": "../../../img/service/iphone/phones/iph10.png",
        "iPhone 11": "../../../img/service/iphone/phones/iph11.png",
        "iPhone 12": "../../../img/service/iphone/phones/iph12.png",
        "iPhone 13": "../../../img/service/iphone/phones/iph13.png",
        "iPhone 14": "../../../img/service/iphone/phones/iph14.png",
        "iPhone 15": "../../../img/service/iphone/phones/iph15.png",

        "MacBook Air":"../../../img/service/iphone/newForm/m1.png",
        "MacBook Pro":"../../../img/service/iphone/newForm/m1.png",
        "iMac":"../../../img/service/iphone/newForm/iMac.png",
        "Mac Pro":"../../../img/service/iphone/newForm/Mac Pro.png",
        "Mac mini":"../../../img/service/iphone/newForm/Mac mini.png",
        "Mac Studio":"../../../img/service/iphone/newForm/macStudio.png",
        "Mac Display":"../../../img/service/iphone/newForm/iMac.png",
        "Mac Zubehör":"../../../img/service/iphone/newForm/Mac Zubehör.png",



        "iPad mini":"../../../img/service/iphone/ipad/iPad mini.png",
        "iPad":"../../../img/service/iphone/ipad/ipad10.png",
        "iPad Air":"../../../img/service/iphone/ipad/iPad Air.png",
        "iPad Pro 10,5":"../../../img/service/iphone/ipad/iPad Pro 10,5.png",
        "iPad Pro 11":"../../../img/service/iphone/ipad/iPad Pro 11.png",
        "iPad Pro 12,9":"../../../img/service/iphone/ipad/iPad Pro 12,9.png",


        
       
    },
           
    }
  }
  componentDidUpdate(prevProps: Readonly<ICardImageProps>, prevState: Readonly<ICardImageState>, snapshot?: any): void {
    
  }


  public render() {
    return (
        <>
  {this.props.isWithimg?
        <button 
        onClick={this.props.onClick}
        className={`bg-white bg-opacity-60 shadow-md rounded p-1 max-w-[260px] md:w-full transition duration-500 ease-in-out border w-full hover:scale-110 hover:border-black`}>

          {/*  <!--  Image --> */}
          <figure>
            <img
              src={this.state.allImge[this.props.name]}
              alt="card"
              className="aspect-video w-full"
            />
          </figure>
          {/*  <!-- Body--> */}
          <div className="py-3">
            <header className="">
              <h3 className="text-xl font-bold text-black  text-center">
                {this.props.name}
              </h3>
            </header>
          </div>
        </button>

        :
        <button
        onClick={this.props.onClick}
        className='shadow-lg shadow-[#000] rounded-md bg-[#000] p-2 max-w-sm md:w-1/3 transition duration-500 ease-in-out border
        w-full hover:scale-110 hover:border-black'>
          {/*  <!-- Body--> */}
          <div className="">
            <header className="">
              <h3 className="text-lg font-bold text-white  text-center">
                {this.props.name}
              </h3>
            </header>
          </div>

        </button>
        }
      </>
    );
  }
}
