import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface IFeatureSectionColorDesignProps {
    title: string;
    feature :{
        title: string;
        description: string;
    }[]
}

export interface IFeatureSectionColorDesignState {
}

export default class FeatureSectionColorDesign extends React.Component<IFeatureSectionColorDesignProps, IFeatureSectionColorDesignState> {
  constructor(props: IFeatureSectionColorDesignProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <div className="font-[sans-serif] text-white bg-gradient-to-r from-[#4f7b95] to-black py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="sm:text-4xl text-2xl font-extrabold text-center mb-16">{this.props.title}</h2>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-12 max-md:max-w-lg mx-auto">
          <div className="rounded-xl group p-8 text-center hover:bg-white hover:text-[#4f7b95] hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-semibold mb-2">{this.props.feature[0].title}</h3>
            <p className="text-gray-300 group-hover:text-gray-500 text-sm">{this.props.feature[0].description}</p>
            <div className=" justify-center w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-[#4f7b95] bg-opacity-100 py-2 px-1 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
          </div>
          <div className="rounded-xl group p-8 text-center hover:bg-white hover:text-[#4f7b95] hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-semibold mb-2">{this.props.feature[1].title}</h3>
            <p className="text-gray-300 group-hover:text-gray-500 text-sm">{this.props.feature[1].description}</p>
            <div className=" justify-center w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-[#4f7b95] bg-opacity-100 py-2 px-1 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
          </div>
          <div className="rounded-xl group p-8 text-center hover:bg-white hover:text-[#4f7b95] hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-semibold mb-2">{this.props.feature[2].title}</h3>
            <p className="text-gray-300 group-hover:text-gray-500 text-sm">{this.props.feature[2].description}</p>
            <div className=" justify-center w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-[#4f7b95] bg-opacity-100 py-2 px-1 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
          </div>
          <div className="rounded-xl group p-8 text-center hover:bg-white hover:text-[#4f7b95] hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-semibold mb-2">{this.props.feature[3].title}</h3>
            <p className="text-gray-300 group-hover:text-gray-500 text-sm">{this.props.feature[3].description}</p>
            <div className=" justify-center w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-[#4f7b95] bg-opacity-100 py-2 px-1 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
          </div>
          <div className="rounded-xl group p-8 text-center hover:bg-white hover:text-[#4f7b95] hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-semibold mb-2">{this.props.feature[4].title}</h3>
            <p className="text-gray-300 group-hover:text-gray-500 text-sm">{this.props.feature[4].description}</p>
            <div className=" justify-center w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-[#4f7b95] bg-opacity-100 py-2 px-1 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
          </div>
          <div className="rounded-xl group p-8 text-center hover:bg-white hover:text-[#4f7b95] hover:shadow-xl transition duration-300">
            <h3 className="text-xl font-semibold mb-2">{this.props.feature[5].title}</h3>
            <p className="text-gray-300 group-hover:text-gray-500 text-sm">{this.props.feature[5].description}</p>
            <div className=" justify-center w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-[#4f7b95] bg-opacity-100 py-2 px-1 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    );
  }
}
{/**
ues it in this way:
<FeatureSectionColorDesign title='Our Exclusive Features' feature={[
    {
        title: 'Customization',
        description: 'Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id. Dolore et sint mollit in nisi tempor culpa consectetur. Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id.'
    },
    {
        title: 'Performance',
        description: 'Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id. Dolore et sint mollit in nisi tempor culpa consectetur. Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id.'
    },
    {
        title: 'Security',
        description: 'Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id. Dolore et sint mollit in nisi tempor culpa consectetur. Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id.'
    },
    {
        title: 'Support',
        description: 'Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id. Dolore et sint mollit in nisi tempor culpa consectetur. Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id.'
    },
    {
        title: 'Scalability',
        description: 'Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id. Dolore et sint mollit in nisi tempor culpa consectetur. Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id.'
    },
    {
        title: 'Reliability',
        description: 'Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id. Dolore et sint mollit in nisi tempor culpa consectetur. Qui elit labore in nisi dolore tempor anim laboris ipsum ad ad consequat id.'
    }
]} />
*/
}